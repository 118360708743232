import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  // cibStackOverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilArrowLeft,
  // cilCircleCheck,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilTags,
  cilTask,
  cilTrash,
  cilTruck,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilXCircle,
  cilWarning,
  cilRemove,
  cilApplications,
  cilHandPointUp,
  cilX,
  cilPlus,
  cilAccountLogout,
  cilMobile,
  cilFullscreen,
  cilClearAll
} from '@coreui/icons'

export const iconsSet = Object.assign({}, {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  // cilCircleCheck,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilTags,
  cilTask,
  cilTrash,
  cilTruck,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilXCircle,
  cilWarning,
  cilRemove,
  cilApplications,
  cilHandPointUp,
  cilX,
  cilPlus,
  cilAccountLogout,
  cilFullscreen,
  cilArrowLeft
}, {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
}, {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  // cibStackOverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cilClearAll
})
